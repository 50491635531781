import React, { useContext } from 'react';
import Sheet from 'react-modal-sheet';
import { Button, Typography } from 'antd';
import Input from './Input';
import prompt from "antd-prompt";
import { CloseCircleFilled, SettingTwoTone, CheckCircleTwoTone, SyncOutlined, DownloadOutlined, CaretRightOutlined } from '@ant-design/icons';
import GoogleAnalytics from 'react-ga';

import { CodeEditor } from './CodeEditor';
import { parseInput } from '../lib/translator';
import { usePrevious } from '../hooks/useprev';
import BoardContext from '../contexts/BoardContext';
import examples from '../assets/js/examples';


const { Title } = Typography;


function Menu({ currentDir, executingProgram, sCurrentDir, onError }) {

  const { registers: { pch: pchRef, pcl: pclRef } } = useContext(BoardContext);

  const [isOpen, setOpen] = React.useState(false);
  const [startDir, setStartDir] = React.useState(currentDir);
  const [operations, setOperations] = React.useState('');
  const [tOperations, setTOPerations] = React.useState('');
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [canSetPC, setCanSetPc] = React.useState(false);
  const [markedErrors, setMarkedErrors] = React.useState([]);
  const markedErrorsPrev = usePrevious(markedErrors);
  const editor = React.useRef();
  const pchInput = pchRef.current;
  const pclInput = pclRef.current;


  React.useEffect(() => {
    if (isOpen) {
      GoogleAnalytics.pageview('/openMenu');
    }
  }, [isOpen]);
  React.useEffect(() => {
    setTOPerations('');
    const { results: result, errors } = parseInput(operations, startDir);
    markErrors(errors);
    setError(errors.length > 0);

    if (result.length === 0) return;
    setTOPerations([].concat.apply([], result).join('\n').toUpperCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operations, startDir]);

  React.useEffect(() => {
    window.lineNumberStart = startDir;
  }, [startDir]);


  const markErrors = (lineErrors) => {
    if (markedErrorsPrev && markedErrorsPrev.length > 0) {
      markedErrorsPrev.forEach((me) => {
        me.clear();
      })
    }

    const markedResults = [];
    for (let i = 0; i < lineErrors.length; i++) {
      const { lineFrom, lineTo } = lineErrors[i];

      if (editor.current) {
        const markResult = editor.current.editor.markText({ line: lineFrom - 1 }, { line: lineTo - 1 }, { className: 'error-underline' });
        markedResults.push(markResult);
      }
    }
    setMarkedErrors(markedResults);
  };

  return (
    <>

      <Button disabled={executingProgram} onClick={() => setOpen(true)} style={{ position: 'absolute', bottom: 20, left: 20, opacity: 1 }} icon={<SettingTwoTone />}>Programming</Button>

      <Sheet isOpen={isOpen} onClose={() => setOpen(false)} snapPoints={[500, 0]} disableDrag >
        <Sheet.Container>
          <Sheet.Header />
          <Title style={{ padding: 10 }}>Programming  <Button type="dashed" size={20} icon={<CloseCircleFilled />} onClick={() => setOpen(false)}>
            close
            </Button>
          </Title>

          {/* <Divider /> */}
          <Sheet.Content>


            <div style={{ padding: 10 }}>

              <div style={{ marginTop: 5, marginBottom: 5, overflowX: 'scroll', whiteSpace: 'nowrap', scrollbarWidth: 'none', display: 'flex', flexDirection: 'row' }}>
                <p style={{ marginRight: 5 }}><b>Examples</b></p> <CaretRightOutlined /> {examples.map(({ name, code, icon }) => <Button key={name} onClick={() => setOperations(code.join('\n'))} style={{ marginRight: 5 }} icon={icon}>{name}</Button>)}
              </div>
              <div style={{ marginTop: 5, marginBottom: 5 }}>
                <Input addonBefore="Start dir" value={startDir} defaultValue={startDir} onChange={(e) => /^[a-f0-9]{0,4}$/i.test(e.target.value) ? setStartDir(e.target.value) : true} />
              </div>

              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ width: '50%' }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <p><b>Assembly code</b></p>
                    <Button
                      disabled={tOperations.length === 0 || startDir.length !== 4 || error}
                      style={{ marginLeft: 7 }}
                      size='small'
                      onClick={async () => {
                        try {
                          const filename = await prompt({
                            modalProps: { zIndex: 21499995, closable: false, cancelButtonProps: { hidden: true, }, destroyOnClose: true, maskClosable: false, okText: 'Download' },
                            title: "Enter filename",
                            placeholder: "SUM AC<=B+C.txt",
                            rules: [
                              // check this link for more help: https://ant.design/components/form/#Validation-Rules
                              {
                                required: true,
                                message: "It should end with '.txt' extension",
                                pattern: /.*\.txt$/gmi
                              }
                            ]
                          });

                          const element = document.createElement("a");
                          const file = new Blob([operations], { type: 'text/plain' });
                          element.href = URL.createObjectURL(file);
                          element.download = filename.endsWith('.txt') ? filename : filename + '.txt';
                          document.body.appendChild(element); // Required for this to work in FireFox
                          element.click();
                        } catch (e) {
                          console.error("Error enter filename")
                        }

                      }}
                      icon={<DownloadOutlined download />}
                    >Download</Button>
                  </div>

                  <CodeEditor code={operations} onChange={(txt) => setOperations(txt)} width='100%' ref={editor} />
                </div>
                <div style={{ width: '50%', paddingLeft: 5 }}>
                  <p><b>Machine code</b></p>
                  <CodeEditor code={tOperations} onChange={(txt) => setTOPerations(txt)} lineNumberStart={startDir} width='100%' disabled />
                </div>
              </div>
              <div style={{ marginTop: 5 }}>
                <Button
                  disabled={tOperations.length === 0 || startDir.length !== 4 || error}
                  onClick={() => {
                    let ops = tOperations.split('\n').map(op => op.replace(/\s/g, ''))
                    ops = ops.map(op => op.toUpperCase());
                    ops = ops.filter((op) => op.length === 2);
                    setCanSetPc(true);
                    try {
                      window.loadOperations(startDir.toUpperCase(), ops);
                    } catch (error) {
                      setCanSetPc(false);
                      onError(error);
                    }

                    setLoaded(true);
                    setTimeout(() => setLoaded(false), 500);
                  }}
                  icon={<SyncOutlined spin={loaded} />}
                >Load to memory</Button>
              </div>

              <div style={{ marginTop: 5 }}>
                <Button
                  disabled={tOperations.length === 0 || startDir.length !== 4 || loaded || error || !canSetPC}
                  onClick={() => {
                    pchInput.setValue(startDir.substring(0, 2));
                    pclInput.setValue(startDir.substring(2, 4));

                    sCurrentDir(startDir)
                    setOpen(false);
                  }}
                  icon={<CheckCircleTwoTone />}
                >Set PC to {startDir}</Button>
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop />
      </Sheet>
    </>
  );

}

export default Menu;