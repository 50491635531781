import { hex2dec } from "../utils/operations";

function init(
  {
    ri,
    pcInput,
    rb,
    rc,
    rd,
    re,
    acc,
    op2,
    selAlu,

    memoryTable,

    riCar,
    acCar,
    regCar,
    acBus,
    car2,
    pcCar,
    memBus,
    memRw,
    hCar,
    lCar,
    selFL,
    fc,
    fz,
    flCar,
    h,
    l,
    selReg,
    pchCar,
    pclCar,
    pch,
    pcl,
    sp,
    spCar,
  },
  { sCurrentDir }
) {

  window.emitter.on("INPUT_ON_CHANGE", (i) => {
    if (typeof i !== "string") {
      i.highlight();
    }
    switch (i) {
      case ri.current:
        riCar.current.setChecked(true);
        // i.input.style.backgroundColor = "white";
        break;
      case acc.current:
        acCar.current.setChecked(true);
        break;
      case op2.current:
        car2.current.setChecked(true);
        break;
      case pch.current:
        pchCar.current.setChecked(true);
        // i.input.style.backgroundColor = "white";
        break;
      case pcl.current:
        pclCar.current.setChecked(true);
        // i.input.style.backgroundColor = "white";
        break;
      case sp.current:
        spCar.current.setChecked(true);
        break;
      case "pcInput":
        const pcValue = pcInput.getValue(true);
        sCurrentDir(pcValue);
        pcCar.current.setChecked(true);
        memoryTable.current.scrollToRow(hex2dec(pcValue));
        break;
      case h.current:
        hCar.current.setChecked(true);
        break;
      case l.current:
        lCar.current.setChecked(true);
        break;
      case rb.current:
      case rc.current:
      case rd.current:
      case re.current:
        regCar.current.setChecked(true);
        break;
      default:
        break;
    }
  });

  window.emitter.on("INPUT_GET_VALUE", (i) => {
    // console.log("INPUT_GET_VALUE", i);
    // i.focus();
    switch (i) {
      case rb.current:
        selReg.current.setValue("00");
        break;
      case rc.current:
        selReg.current.setValue("10");
        break;
      case rd.current:
        selReg.current.setValue("01");
        break;
      case re.current:
        selReg.current.setValue("11");
        break;
      case acc.current:
        acBus.current.setChecked(true);
        break;
      default:
        break;
    }
  });

  window.emitter.on("MEMORY_GET_VALUE", () => {
    memBus.current.setChecked(true);
    memRw.current.setChecked(false);
  });

  window.emitter.on("MEMORY_SET_VALUE", () => {
    memRw.current.setChecked(true);
    memBus.current.setChecked(false);
  });
  window.emitter.on("SWITCH_ACTIVATE", (s) => {
    switch (s) {
      case fc.current:
      case fz.current:
        // TODO
        selFL.current.setChecked(true);
        flCar.current.setChecked(true);
        break;

      default:
        break;
    }
  });
  // window.emitter.on("SWITCH_DESACTIVATE", (s) => { // no se
  //   switch (s) {
  //     case fcSwitch:
  //     case fzSwitch:
  //       // TODO
  //       selFL.current.setChecked(true);
  //       flCar.current.setChecked(true);
  //       break;

  //     default:
  //       break;
  //   }
  // });

  window.emitter.on("OVERFLOW", () => {
    fc.current.setChecked(true);
  });

  window.emitter.on("RESULT_ZERO", () => {
    fz.current.setChecked(true);
  });

  // window.emitter.on("SWITCH_DESACTIVATE", (s) => {});
  window.emitter.on("ALU_OPERATION", (op) => {
    switch (op) {
      case "ADD":
        selAlu.current.setValue("000");
        // selFL.current.setChecked(true);
        break;

      case "SUB":
      case "CMP": // CMP uses sub to compare
        selAlu.current.setValue("001");
        // selFL.current.setChecked(true);
        break;

      case "AND":
        selAlu.current.setValue("010");
        // selFL.current.setChecked(true);
        break;

      case "OR":
        selAlu.current.setValue("011");
        // selFL.current.setChecked(true);
        break;

      case "XOR":
        selAlu.current.setValue("100");
        // selFL.current.setChecked(true);
        break;

      case "NOT":
        selAlu.current.setValue("101");
        // selFL.current.setChecked(true);
        break;

      case "DIRECT":
        selAlu.current.setValue("110");
        break;

      case "INC":
        selAlu.current.setValue("111");
        // selFL.current.setChecked(true);
        break;

      default:
        break;
    }
  });
  window.emitter.on("PC_UPDATED", ({ pchValue, pclValue }) => {
    sCurrentDir(pchValue + pclValue);
    pcInput.setValue(pchValue + pclValue);
  });
}

export { init };
