import { ToastProvider, useToasts } from 'react-toast-notifications';
import { Button } from 'antd';

import {
  PlaySquareTwoTone,
  StopTwoTone,
  ThunderboltTwoTone,
} from '@ant-design/icons';
import { init } from './lib/listeners'
import { EventEmitter } from 'events';
import GoogleAnalytics from 'react-ga';

import React from 'react';
import Registers from './components/Registers';
import ALU from './components/ALU';
import Memory from './components/Memory';

import cpuImage from './assets/img/m++.png';

import UnitControl from './components/UnitControl';
import Flags from './components/Flags';
import Menu from './components/Menu';

import { resetSignals } from './utils/switch';
import {
  add,
  addt2,
  and,
  andt2,
  bc,
  beq,
  call,
  cma,
  cmp,
  cmpt2,
  inc,
  inisp,
  iret,
  jmp,
  lda,
  ldax,
  lfa,
  mv,
  mvt2,
  or,
  ort2,
  pop,
  push,
  ret,
  sfa,
  sta,
  stax,
  step,
  sub,
  subt2,
  xor,
  xort2,
} from './lib/cpu';
import { dec2hex, hex2dec } from './utils/operations';
import Welcome from './components/Welcome';
import Console from './components/Console';
import { BoardContextProvider } from './contexts/BoardContext';
import Clock from './components/Clock';
import Author from './components/Author';
import { resetFocus } from './utils/input';

window.emitter = new EventEmitter();

const TESTING = typeof process.argv.find((s) => s.includes('jest')) !== 'undefined';
let lastTimeExecuted;

function App() {
  const { addToast } = useToasts();

  const [currentDir, sCurrentDir] = React.useState('0000');
  const [welcomed, setWelcomed] = React.useState(false);
  const [runInstructionActivated, setRunInstructionActivated] = React.useState(
    true,
  );
  const [executingProgram, setExecutingProgram] = React.useState(false);

  // Registers
  const rb = React.useRef();
  const rc = React.useRef();
  const rd = React.useRef();
  const re = React.useRef();
  const regCar = React.useRef();
  const selReg = React.useRef();
  const regBus = React.useRef();

  // ALU
  const accum = React.useRef();
  const op2 = React.useRef();
  const acCAR = React.useRef();
  const acBus = React.useRef();
  const car2 = React.useRef();
  const selAlu = React.useRef();
  const aluBus = React.useRef();

  // Memory
  const hCar = React.useRef();
  const h = React.useRef();
  const l = React.useRef();
  const lCar = React.useRef();
  const memBus = React.useRef();
  const memRw = React.useRef();
  const memory = React.useRef();
  const pcCar = React.useRef();
  const selDir = React.useRef();
  const pc = React.useRef();
  // const pcBus = React.useRef();
  const pchCar = React.useRef();
  const pch = React.useRef();
  const pchBus = React.useRef();
  const pclBus = React.useRef();
  const pclCar = React.useRef();
  const pcl = React.useRef();
  const spCar = React.useRef();
  const sp = React.useRef();

  // UnitControl
  const flBus = React.useRef();
  const riCar = React.useRef();
  const ri = React.useRef();

  // Flags
  const fc = React.useRef();
  const flCar = React.useRef();
  const fz = React.useRef();
  const selFl = React.useRef();

  // Clock
  const inta = React.useRef();
  const eoi = React.useRef();
  const intr = React.useRef();

  const consoleRef = React.useRef();

  const setCurrentDir = (dir, executeStep = true, executeDirectly = false) => {
    // executeDirectly is to let the code call this function without time limit as the button.
    const timeNow = new Date().getTime();
    if (dir === 'FFFF') {
      return window.emitter.emit('PROGRAM_STOPPED');
    }

    if (lastTimeExecuted && !executeDirectly) {
      const timeDiff = timeNow - lastTimeExecuted;
      if (timeDiff < 0) {
        // THIS DOESNT MAKE SENSE NOW, MUST BE COMMENTED. i DONT COMMENT IT KNOW BECAUSE I DONT WANT TO BREAK SOMETHING (IT WOULD NOT HAVE TO PASS)
        return console.info('PREVENTING', timeNow);
      }
    }
    lastTimeExecuted = timeNow;

    sCurrentDir(dir);
    setRunInstructionActivated(false);

    executeOperation(
      {
        riInput: ri.current,
        pcInput,
        memoryTable: memory.current,
        rbInput: rb.current,
        rcInput: rc.current,
        rdInput: rd.current,
        reInput: re.current,
        accInput: accum.current,
        op2Input: op2.current,
        lInput: l.current,
        hInput: h.current,
        riCarSwitch: riCar.current,
        acCarSwitch: acCAR.current,
        regCarSwitch: regCar.current,
        acBusSwitch: acBus.current,
        car2Switch: car2.current,
        pcCarSwitch: pcCar.current,
        memBusSwitch: memBus.current,
        memRwSwitch: memRw.current,
        hCarSwitch: hCar.current,
        lCarSwitch: lCar.current,
        fzSwitch: fz.current,
        selFLSwitch: selFl.current,
        fcSwitch: fc.current,
        aluBusSwitch: aluBus.current,
        selDirSwitch: selDir.current,
        flCarSwitch: flCar.current,
        spInput: sp.current,
        pchBusSwitch: pchBus.current,
        pchCarSwitch: pchCar.current,
        pclBusSwitch: pclBus.current,
        pclCarSwitch: pclCar.current,
        spCarSwitch: spCar.current,
        eoiSwitch: eoi.current,
        pchInput: pch.current,
        pclInput: pcl.current,
      },
      executeStep,
    )
      .then(() => {
        setRunInstructionActivated(true);
      })
      .catch((error) => {
        console.error(error);
        addToast(error.message || error, {
          appearance: 'error',
          autoDismissTimeout: 4000,
        });
        window.emitter.emit('PROGRAM_STOPPED');
      });
    // }, 100);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pcInput = {
    getValue(emit) {
      return pch.current.getValue(emit) + pcl.current.getValue(emit);
    },
    setValue(s) {
      if (!s || s.length !== 4) throw new Error('ERR_BAD_PC_VALUE');
      const pchValue = s.substring(0, 2);
      const pclValue = s.substring(2, 4);
      pch.current.setValue(pchValue);
      pcl.current.setValue(pclValue);

      window.emitter.emit('INPUT_ON_CHANGE', 'pcInput');
    },
  };

  const contextObj = {
    registers: {
      rb,
      rc,
      rd,
      re,
      accum,
      ri,
      pc,
      pcl,
      pch,
      h,
      l,
      sp,
      op2,
    },
    statuses: {
      regCar,
      selReg,
      regBus,
      acCAR,
      acBus,
      car2,
      selAlu,
      aluBus,
      hCar,
      lCar,
      memBus,
      memRw,
      pcCar,
      selDir,
      pchCar,
      pclCar,
      pchBus,
      pclBus,
      spCar,
      flBus,
      riCar,
      flCar,
      selFl,
      inta,
      eoi,
      intr,
    },
    flags: { fc, fz },
    console: consoleRef,
    memory,
  };

  React.useEffect(() => {
    !TESTING && window.scrollTo(0, 0); // because resizing moves the window

    !TESTING && GoogleAnalytics.initialize('G-84NZ5GXMRF');
    GoogleAnalytics.pageview('/init');

    welcomed && init({
      acBus,
      acCar: acCAR,
      acc: accum,
      car2,
      fc, flCar, fz, h, hCar,
      l, lCar, memBus, memRw, memoryTable: memory, op2, pcInput, pcCar, pch, pchCar, pcl, pclCar, rb, rc, rd, re, regCar, ri, riCar, selAlu, selFL: selFl, selReg, sp, spCar
    }, { sCurrentDir });

    console.log = (...args) => {
      if (args.length === 0) return;
      // console.info(...args);
      if (args[0] && args[0].startsWith && args[0].startsWith('OP')) {
        // consoleRef.current.clearStdout()
        if (consoleRef.current.state.stdout.length >= 30) {
          // consoleRef.current.props.clearInput();
          // console.info(consoleRef.current.clearStdout())
          consoleRef.current.setState({
            stdout: consoleRef.current.state.stdout.slice(
              consoleRef.current.state.stdout.length - 30,
              consoleRef.current.state.stdout.length,
            ),
          });
        }
        consoleRef.current.terminalInput.current.value = args.join(' ');
        consoleRef.current.processCommand();
      } else {
        // console.info(...args);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welcomed]);

  if (TESTING && !welcomed) {
    setWelcomed(true);
  }
  return (
    <BoardContextProvider value={contextObj} className="App">
      {!TESTING && <Welcome onReady={() => setWelcomed(true)} />}
      {welcomed && (
        <div>
          <img src={cpuImage} alt="CPU" style={{ maxWidth: 1440 }} />

          <Registers />
          <ALU />
          <Memory />
          <UnitControl />
          <Flags />
          <Clock />
          <Menu
            setCurrentDir={setCurrentDir}
            currentDir={currentDir}
            executingProgram={executingProgram}
            sCurrentDir={sCurrentDir}
            onError={(error) => {
              addToast(error.message || error, {
                appearance: 'error',
                autoDismissTimeout: 4000,
              });
              window.emitter.emit('PROGRAM_STOPPED');
            }}
          />

          <Author />
          <Console consoleRef={consoleRef} />

          <Button
            onClick={() => setCurrentDir(dec2hex(hex2dec(currentDir), 4))}
            style={{
              position: 'absolute',
              bottom: 60,
              left: 20,
              opacity: 1,
            }}
            icon={<PlaySquareTwoTone />}
            disabled={!runInstructionActivated || executingProgram}
            id="execute-instruction"
          >
            Execute instruction (
            {dec2hex(hex2dec(currentDir), 4)}
            )
          </Button>
          {!executingProgram ? (
            <Button
              onClick={() => {
                setExecutingProgram(true);
                consoleRef.current.clearStdout();
                window.executingProgram = true;
                const int = setInterval(() => {
                  if (window.executingProgram) {
                    setCurrentDir(dec2hex(hex2dec(currentDir), 4));
                  } else {
                    clearInterval(int);
                  }
                }, 200);
                window.emitter.once('PROGRAM_STOPPED', () => {
                  window.executingProgram = false;
                  setExecutingProgram(false);
                  clearInterval(int);
                });
              }}
              style={{
                position: 'absolute',
                bottom: 100,
                left: 20,
                opacity: 1,
              }}
              icon={<ThunderboltTwoTone />}
              disabled={!runInstructionActivated}
            >
              Execute program
            </Button>
          ) : (
            <Button
              onClick={() => {
                window.executingProgram = false;
                setExecutingProgram(false);
              }}
              style={{
                position: 'absolute',
                bottom: 100,
                left: 20,
                opacity: 1,
              }}
              icon={<StopTwoTone />}
            // disabled={!runInstructionActivated}
            >
              Cancel
            </Button>
          )}
        </div>
      )}
    </BoardContextProvider>
  );


  async function executeOperation(
    {
      riInput,
      pcInput,
      memoryTable,
      rbInput,
      rcInput,
      rdInput,
      reInput,
      accInput,
      op2Input,
      lInput,
      hInput,
      pchInput,
      pclInput,

      riCarSwitch,
      acCarSwitch,
      regCarSwitch,
      acBusSwitch,
      car2Switch,
      pcCarSwitch,
      memBusSwitch,
      memRwSwitch,
      hCarSwitch,
      lCarSwitch,
      fzSwitch,
      fcSwitch,
      selFLSwitch,
      aluBusSwitch,
      selDirSwitch,
      flCarSwitch,
      spInput,
      pchCarSwitch,
      spCarSwitch,
      pclCarSwitch,
      pchBusSwitch,
      pclBusSwitch,
      eoiSwitch,
    },
    executeStep,
  ) {
    await resetSignals(
      riCarSwitch,
      acCarSwitch,
      regCarSwitch,
      acBusSwitch,
      car2Switch,
      pcCarSwitch,
      memBusSwitch,
      memRwSwitch,
      hCarSwitch,
      lCarSwitch,
      fzSwitch,
      fcSwitch,
      aluBusSwitch,
      selDirSwitch,
      selFLSwitch,
      flCarSwitch,
      spCarSwitch,
      pchCarSwitch,
      pclCarSwitch,
      pchBusSwitch,
      pclBusSwitch,
    );

    resetFocus(
      riInput,
      pcInput,
      rbInput,
      rcInput,
      rdInput,
      reInput,
      accInput,
      op2Input,
      lInput,
      hInput,
      pchInput,
      pclInput,
      selAlu.current,
      selReg.current,
      sp.current,
    );

    if (executeStep) {
      step(riInput, pcInput, memoryTable);
    }

    switch (riInput.getValue()) {
      case '00':
        // MOV B, B
        console.log('OP', 'MOV B, B');
        mv(rbInput, rbInput, op2Input);
        break;

      case '01':
        // MOV C, B
        console.log('OP', 'MOV C, B');
        mv(rbInput, rcInput, op2Input);
        break;

      case '02':
        // MOV D, B
        console.log('OP', 'MOV D, B');
        mv(rbInput, rdInput, op2Input);
        break;
      case '03':
        // MOV E, B
        console.log('OP', 'MOV E, B');
        mv(rbInput, reInput, op2Input);
        break;
      case '04':
        // MOV B, C
        console.log('OP', 'MOV B, C');
        mv(rcInput, rbInput, op2Input);
        break;
      case '05':
        // MOV C, C
        console.log('OP', 'MOV C, C');
        mv(rcInput, rcInput, op2Input);
        break;
      case '06':
        // MOV D, C
        console.log('OP', 'MOV D, C');
        mv(rcInput, rdInput, op2Input);
        break;
      case '07':
        // MOV E, C
        console.log('OP', 'MOV E, C');
        mv(rcInput, reInput, op2Input);
        break;
      case '08':
        // MOV B, D
        console.log('OP', 'MOV B, D');
        mv(rdInput, rbInput, op2Input);
        break;
      case '09':
        // MOV C, D
        console.log('OP', 'MOV C, D');
        mv(rdInput, rcInput, op2Input);
        break;
      case '0A':
        // MOV D, D
        console.log('OP', 'MOV D, D');
        mv(rdInput, rdInput, op2Input);
        break;

      case '0B':
        // MOV E, D
        console.log('OP', 'MOV E, D');
        mv(rdInput, reInput, op2Input);
        break;
      case '0C':
        // MOV B, E
        console.log('OP', 'MOV B, E');
        mv(reInput, rbInput, op2Input);
        break;
      case '0D':
        // MOV C, E
        console.log('OP', 'MOV C, E');
        mv(reInput, rcInput, op2Input);
        break;
      case '0E':
        // MOV D, E
        console.log('OP', 'MOV D, E');
        mv(reInput, rdInput, op2Input);
        break;
      case '0F':
        // MOV E, E
        console.log('OP', 'MOV E, E');
        mv(reInput, reInput, op2Input);
        break;

      case '10':
        // MOV B, AC
        console.log('OP', 'MOV B, AC');
        mv(accInput, rbInput, op2Input);
        break;
      case '11':
        // MOV C, AC
        console.log('OP', 'MOV C, AC');
        mv(accInput, rcInput, op2Input);
        break;
      case '12':
        // MOV D, AC
        console.log('OP', 'MOV D, AC');
        mv(accInput, rdInput, op2Input);
        break;
      case '13':
        // MOV E, AC
        console.log('OP', 'MOV E, AC');
        mv(accInput, reInput, op2Input);
        break;

      // ------------------- GROUP 2 -------------------

      case '18':
        // SUB B
        console.log('OP', 'SUB B');
        sub(false, {
          sourceInput: rbInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;

      case '19':
        // SUB C
        console.log('OP', 'SUB C');
        sub(false, {
          sourceInput: rcInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '1A':
        // SUB D
        console.log('OP', 'SUB D');
        sub(false, {
          sourceInput: rdInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '1B':
        // SUB E
        console.log('OP', 'SUB E');
        sub(false, {
          sourceInput: reInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;

      case '1C':
        // CMP B
        console.log('OP', 'CMP B');
        cmp({
          sourceInput: rbInput,
          destinationInput: accInput,
          fcSwitch,
          fzSwitch,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '1D':
        // CMP C
        console.log('OP', 'CMP C');
        cmp({
          sourceInput: rcInput,
          destinationInput: accInput,
          fcSwitch,
          fzSwitch,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '1E':
        // CMP D
        console.log('OP', 'CMP D');
        cmp({
          sourceInput: rdInput,
          destinationInput: accInput,
          fcSwitch,
          fzSwitch,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '1F':
        // CMP E
        console.log('OP', 'CMP E');
        cmp({
          sourceInput: reInput,
          destinationInput: accInput,
          fcSwitch,
          fzSwitch,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '20':
        // AND B
        console.log('OP', 'AND B');
        and(false, {
          sourceInput: rbInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '21':
        // AND C
        console.log('OP', 'AND C');
        and(false, {
          sourceInput: rcInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '22':
        // AND D
        console.log('OP', 'AND D');
        and(false, {
          sourceInput: rdInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '23':
        // AND E
        console.log('OP', 'AND E');
        and(false, {
          sourceInput: reInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '24':
        // OR B
        console.log('OP', 'OR B');
        or(false, {
          sourceInput: rbInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '25':
        // OR C
        console.log('OP', 'OR C');
        or(false, {
          sourceInput: rcInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '26':
        // OR D
        console.log('OP', 'OR D');
        or(false, {
          sourceInput: rdInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '27':
        // OR E
        console.log('OP', 'OR E');
        or(false, {
          sourceInput: reInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '28':
        // XOR B
        console.log('OP', 'XOR B');
        xor(false, {
          sourceInput: rbInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '29':
        // XOR C
        console.log('OP', 'XOR C');
        xor(false, {
          sourceInput: rcInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '2A':
        // XOR D
        console.log('OP', 'XOR D');
        xor(false, {
          sourceInput: rdInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '2B':
        // XOR E
        console.log('OP', 'XOR E');
        xor(false, {
          sourceInput: reInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '2C':
        // INC B
        console.log('OP', 'INC B');
        inc(false, {
          sourceInput: rbInput,
          destinationInput: rbInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '2D':
        // INC C
        console.log('OP', 'INC C');
        inc(false, {
          sourceInput: rcInput,
          destinationInput: rcInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '2E':
        // INC D
        console.log('OP', 'INC D');
        inc(false, {
          sourceInput: rdInput,
          destinationInput: rdInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '2F':
        // INC E
        console.log('OP', 'INC E');
        inc(false, {
          sourceInput: reInput,
          destinationInput: reInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;

      case '30':
        // ADD B
        console.log('OP', 'ADD B');
        add(false, {
          sourceInput: rbInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '31':
        // ADD C
        console.log('OP', 'ADD C');
        add(false, {
          sourceInput: rcInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '32':
        // ADD D
        console.log('OP', 'ADD D');
        add(false, {
          sourceInput: rdInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '33':
        // ADD E
        console.log('OP', 'ADD E');
        add(false, {
          sourceInput: reInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      // ------------------- GROUP 3 -------------------
      case '40':
        // MV AC, B
        console.log('OP', 'MV AC, B');
        mv(rbInput, accInput, op2Input);
        break;
      case '41':
        // MV AC, C
        console.log('OP', 'MV AC, C');
        mv(rcInput, accInput, op2Input);

        break;
      case '42':
        // MV AC, D
        console.log('OP', 'MV AC, D');
        mv(rdInput, accInput, op2Input);
        break;
      case '43':
        // MV AC, E
        console.log('OP', 'MV AC, E');
        mv(reInput, accInput, op2Input);
        break;
      case '44':
        // MV AC, E
        console.log('OP', 'MV AC, AC');
        mv(accInput, accInput, op2Input);
        break;
      case '45':
        // ADD AC
        console.log('OP', 'ADD AC');
        add(false, {
          sourceInput: accInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '46':
        // SUB AC
        console.log('OP', 'SUB AC');
        sub(false, {
          sourceInput: accInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '47':
        // CMP AC
        console.log('OP', 'CMP AC');
        cmp({
          sourceInput: accInput,
          destinationInput: accInput,
          fcSwitch,
          fzSwitch,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '48':
        // AND AC
        console.log('OP', 'AND AC');
        and(false, {
          sourceInput: accInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '49':
        // OR AC
        console.log('OP', 'OR AC');
        or(false, {
          sourceInput: accInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '4A':
        // XOR AC
        console.log('OP', 'XOR AC');
        xor(false, {
          sourceInput: accInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '4B':
        // INC AC
        console.log('OP', 'INC AC');
        inc(false, {
          sourceInput: accInput,
          destinationInput: accInput,
          op2Input,
        });
        aluBusSwitch.setChecked(true);
        break;
      // ------------------- GROUP 4 -------------------
      case '60':
        // MV inm, B
        console.log('OP', 'MV inm, B');
        mvt2({
          destinationInput: rbInput,
          memoryTable: memory.current,
          op2Input,
          pcInput,
        });
        break;
      case '61':
        // MV inm, C
        console.log('OP', 'MV inm, C');
        mvt2({
          destinationInput: rcInput,
          memoryTable: memory.current,
          op2Input,
          pcInput,
        });
        break;
      case '62':
        // MV inm, D
        console.log('OP', 'MV inm, D');
        mvt2({
          destinationInput: rdInput,
          memoryTable: memory.current,
          op2Input,
          pcInput,
        });
        break;
      case '63':
        // MV inm, E
        console.log('OP', 'MV inm, E');
        mvt2({
          destinationInput: reInput,
          memoryTable: memory.current,
          op2Input,
          pcInput,
        });
        break;
      case '64':
        // MV inm, AC
        console.log('OP', 'MV inm, AC');
        mvt2({
          destinationInput: accInput,
          memoryTable: memory.current,
          op2Input,
          pcInput,
        });
        break;
      case '65':
        // ADD inm
        console.log('OP', 'ADD inm');
        addt2(false, {
          destinationInput: accInput,
          memoryTable: memory.current,
          op2Input,
          pcInput,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '66':
        // SUB inm
        console.log('OP', 'SUB inm');
        subt2(false, {
          destinationInput: accInput,
          memoryTable: memory.current,
          op2Input,
          pcInput,
        });
        aluBusSwitch.setChecked(true);
        break;

      case '67':
        // CMP inm
        console.log('OP', 'CMP inm');
        cmpt2({
          destinationInput: accInput,
          memoryTable: memory.current,
          op2Input,
          pcInput,
          fcSwitch,
          fzSwitch,
        });
        aluBusSwitch.setChecked(true);
        break;

      case '68':
        // AND inm
        console.log('OP', 'AND inm');
        andt2(false, {
          destinationInput: accInput,
          memoryTable: memory.current,
          op2Input,
          pcInput,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '69':
        // OR inm
        console.log('OP', 'OR inm');
        ort2(false, {
          destinationInput: accInput,
          memoryTable: memory.current,
          op2Input,
          pcInput,
        });
        aluBusSwitch.setChecked(true);
        break;
      case '6A':
        // OR inm
        console.log('OP', 'XOR inm');
        xort2(false, {
          destinationInput: accInput,
          memoryTable: memory.current,
          op2Input,
          pcInput,
        });
        aluBusSwitch.setChecked(true);
        break;

      // ------------------- GROUP 5 -------------------
      case '70':
        // LDA dir
        console.log('OP', 'LDA dir');
        lda({
          destinationInput: accInput,
          hInput,
          lInput,
          memoryTable: memory.current,
          op2Input,
          pcInput,
        });
        selDirSwitch.setChecked(true);
        break;
      case '71':
        // STA dir
        console.log('OP', 'STA dir');
        sta({
          accInput,
          hInput,
          lInput,
          memoryTable: memory.current,
          op2Input,
          pcInput,
        });
        selDirSwitch.setChecked(true);
        break;
      case '72':
        // BEQ
        console.log('OP', 'BEQ');
        const fzActivated = beq({
          pcInput,
          memoryTable: memory.current,
          hInput,
          lInput,
          riInput,
          fzSwitch,
        });

        if (fzActivated) {
          setCurrentDir(
            dec2hex(
              hex2dec(
                pcInput.getValue(true), // currentDir is not having the updated value at this time of the code
              ),
              4,
            ),
            false,
            true,
          );
        }
        selDirSwitch.setChecked(true);
        break;
      case '73':
        // BC
        console.log('OP', 'BC');
        const fcActivated = bc({
          pcInput,
          memoryTable: memory.current,
          hInput,
          lInput,
          riInput,
          fcSwitch,
        });

        if (fcActivated) {
          setCurrentDir(
            dec2hex(
              hex2dec(
                pcInput.getValue(true), // currentDir is not having the updated value at this time of the code
              ),
              4,
            ),
            false,
            true,
          );
        }
        selDirSwitch.setChecked(true);
        break;
      case '74':
        // JMP
        console.log('OP', 'JMP');
        jmp({
          pcInput,
          memoryTable: memory.current,
          hInput,
          lInput,
          riInput,
        });

        setCurrentDir(
          dec2hex(
            hex2dec(
              pcInput.getValue(true), // currentDir is not having the updated value at this time of the code
            ),
            4,
          ),
          false,
          true,
        );

        selDirSwitch.setChecked(true);
        break;
      case '80':
        // CMA
        console.log('OP', 'CMA');
        cma({ accInput });
        break;
      case '81':
        // LFA
        console.log('OP', 'LFA');
        lfa({ accInput, fcSwitch, fzSwitch });
        flCarSwitch.setChecked(true);
        break;
      case '82':
        // SFA
        console.log('OP', 'SFA');
        sfa({ accInput, fcSwitch, fzSwitch });
        flCarSwitch.setChecked(true);
        break;
      case '90':
        // STAX
        console.log('OP', 'STAX');
        stax({
          accInput,
          hInput,
          lInput,
          memoryTable: memory.current,
          op2Input,
          rdInput,
          reInput,
        });
        break;
      case 'B0':
        // LDAX
        console.log('OP', 'LDAX');
        ldax({
          accInput,
          hInput,
          lInput,
          memoryTable: memory.current,
          op2Input,
          rdInput,
          reInput,
        });
        break;
      case 'C1':
        // PUSH
        console.log('OP', 'PUSH');
        push({ accInput, spInput, memoryTable: memory.current });
        break;
      case 'C2':
        // POP
        console.log('OP', 'POP');
        pop({ accInput, spInput, memoryTable: memory.current });
        break;
      case 'C3':
        // INISP dir
        console.log('OP', 'INISP dir');
        inisp({
          hInput,
          lInput,
          memoryTable: memory.current,
          pcInput,
          spInput,
        });
        break;
      case 'C4':
        // CALL dir
        console.log('OP', 'CALL dir');
        call({
          hInput,
          lInput,
          memoryTable: memory.current,
          pcInput,
          spInput,
        });
        break;
      case 'C5':
        // RET
        console.log('OP', 'RET');
        ret({
          memoryTable: memory.current,
          pchInput,
          pclInput,
          spInput,
        });
        break;
      case 'C0':
        // IRET
        iret({
          eoiSwitch,
          fcSwitch,
          fzSwitch,
          memoryTable: memory.current,
          pchInput,
          pclInput,
          spInput,
        });
        break;

      // TODO IRET, INTR (INTR=1)
      case 'FF':
        // FIN
        console.log('OP', 'FIN');
        // restoreLastSignals(); // <=== implement it

        window.emitter.emit('PROGRAM_STOPPED');
        addToast('FIN reached', { appearance: 'success' });
        break;
      default:
        // console.warn(
        //   "Unsuported instruction",
        //   riInput.getValue(true)
        // );
        console.log('OPUN', riInput.getValue(true));
        // addToast(
        //   "Unsuported instruction " +
        //     riInput.getValue(true),
        //   {
        //     appearance: "warning",
        //     autoDismiss: true,
        //   }
        // );
        break;
    }
  }
}

const AppWithToasts = () => (
  <ToastProvider autoDismiss autoDismissTimeout={3000}>
    <App />
  </ToastProvider>
);
export default AppWithToasts;
