const { hex2dec, dec2hex, bin2hex, hex2bin } = require("../utils/operations");

function step(riInput, pcInput, memoryTable) {
  // IR=MEM(PC), PC=PC+1
  const memPC = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  riInput.setValue(memPC); // IR = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1
}

/**
 * General mv operation
 * MOV D, S
 */
function mv(destinationInput, sourceInput, op2Input) {
  window.emitter.emit("ALU_OPERATION", "MV");
  op2Input.setValue(sourceInput.getValue()); // 2OP <= source
  destinationInput.setValue(op2Input.getValue()); // B <= B
}
/**
 * General sub operation
 * SUB D, X
 * calc if only we want the result, if not save the calc to de destinationInput
 */
function sub(onlyCalculation, { sourceInput, destinationInput, op2Input }) {
  window.emitter.emit("ALU_OPERATION", "SUB");
  op2Input.setValue(sourceInput.getValue()); // 2OP <= source

  const destDecimal = hex2dec(destinationInput.getValue());
  const op2Dec = hex2dec(op2Input.getValue());

  let resultDec = destDecimal - op2Dec;
  if (resultDec > 255 || resultDec < 0) {
    if (resultDec > 255) {
      resultDec = resultDec - 255 - 1;
    } else if (resultDec < 0) {
      resultDec = 255 - Math.abs(resultDec) + 1;
    }
    window.emitter.emit("OVERFLOW");
  }
  if (resultDec === 0) {
    window.emitter.emit("RESULT_ZERO");
  }

  const result = dec2hex(resultDec);
  if (!onlyCalculation) {
    destinationInput.setValue(result); // dest = dest - 2OP
  } else {
    return result;
  }
}

function cmp({ sourceInput, destinationInput, op2Input, fzSwitch, fcSwitch }) {
  window.emitter.emit("ALU_OPERATION", "CMP");
  const subResult = sub(true, { sourceInput, destinationInput, op2Input });  // should sub emit event here? because CMP is emiting in the last line
  const resultDec = hex2dec(subResult);

  if (resultDec > 0) {
    // FZ 0, FC 0
    fzSwitch.setChecked(false);
    fcSwitch.setChecked(false);
  } else if (resultDec < 0) {
    // FZ 0, FC 1
    fzSwitch.setChecked(false);
    // fcSwitch.setChecked(true);
    fcSwitch.setChecked(false);
  } else {
    // FZ 1, FC 1
    fzSwitch.setChecked(true);
    // fcSwitch.setChecked(true);
    fcSwitch.setChecked(false);
  }
}

function and(onlyCalculation, { sourceInput, destinationInput, op2Input }) {
  window.emitter.emit("ALU_OPERATION", "AND");
  op2Input.setValue(sourceInput.getValue()); // 2OP <= source

  const op2Dec = hex2dec(op2Input.getValue());
  const destDec = hex2dec(destinationInput.getValue());

  const andDec = destDec & op2Dec;
  const andHex = dec2hex(andDec);

  if (!onlyCalculation) {
    destinationInput.setValue(andHex); // dest = DEST AND 2OP
  } else {
    return andHex;
  }
}

function or(onlyCalculation, { sourceInput, destinationInput, op2Input }) {
  window.emitter.emit("ALU_OPERATION", "OR");
  op2Input.setValue(sourceInput.getValue()); // 2OP <= source

  const op2Dec = hex2dec(op2Input.getValue());
  const destDec = hex2dec(destinationInput.getValue());

  const orDec = destDec | op2Dec;
  const orHex = dec2hex(orDec);

  if (!onlyCalculation) {
    destinationInput.setValue(orHex); // dest = DEST OR 2OP
  } else {
    return orHex;
  }
}

function xor(onlyCalculation, { sourceInput, destinationInput, op2Input }) {
  window.emitter.emit("ALU_OPERATION", "XOR");
  op2Input.setValue(sourceInput.getValue()); // 2OP <= source

  const op2Dec = hex2dec(op2Input.getValue());
  const destDec = hex2dec(destinationInput.getValue());

  const xorDec = destDec ^ op2Dec;
  const xorHex = dec2hex(xorDec);

  if (!onlyCalculation) {
    destinationInput.setValue(xorHex); // dest = DEST XOR 2OP
  } else {
    return xorHex;
  }
}

function inc(onlyCalculation, { sourceInput, destinationInput, op2Input }) {
  window.emitter.emit("ALU_OPERATION", "INC");
  op2Input.setValue(sourceInput.getValue()); // 2OP <= source

  const op2Dec = hex2dec(op2Input.getValue());

  let resultDec = op2Dec + 1;
  if (resultDec > 255 || resultDec < 0) {
    if (resultDec > 255) {
      resultDec = resultDec - 255 - 1;
    } else if (resultDec < 0) {
      resultDec = 255 - Math.abs(resultDec) + 1;
    }
    window.emitter.emit("OVERFLOW");
  }
  if (resultDec === 0) {
    window.emitter.emit("RESULT_ZERO");
  }

  const resultHex = dec2hex(resultDec);
  if (!onlyCalculation) {
    destinationInput.setValue(resultHex); // dest = DEST + 1
  } else {
    return resultHex;
  }
}

function add(onlyCalculation, { sourceInput, destinationInput, op2Input }) {
  window.emitter.emit("ALU_OPERATION", "ADD");
  op2Input.setValue(sourceInput.getValue()); // 2OP <= source

  const op2Dec = hex2dec(op2Input.getValue());
  const destDec = hex2dec(destinationInput.getValue());

  let resultDec = op2Dec + destDec;
  if (resultDec > 255 || resultDec < 0) {
    if (resultDec > 255) {
      resultDec = resultDec - 255 - 1;
    } else if (resultDec < 0) {
      resultDec = 255 - Math.abs(resultDec) + 1;
    }
    window.emitter.emit("OVERFLOW");
  }
  if (resultDec === 0) {
    window.emitter.emit("RESULT_ZERO");
  }

  const resultHex = dec2hex(resultDec);

  if (!onlyCalculation) {
    destinationInput.setValue(resultHex); // dest = DEST + 2OP
  } else {
    return resultHex;
  }
}

function mvt2({ op2Input, pcInput, memoryTable, destinationInput }) {
  const memPC = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  op2Input.setValue(memPC); // 2OP = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  destinationInput.setValue(op2Input.getValue()); // dest <= 2OP
}

function addt2(onlyCalculation, { op2Input, pcInput, memoryTable, destinationInput }) {
  window.emitter.emit("ALU_OPERATION", "ADD");
  const memPC = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  op2Input.setValue(memPC); // 2OP = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  const op2Dec = hex2dec(op2Input.getValue());
  const destDec = hex2dec(destinationInput.getValue());

  let resultDec = op2Dec + destDec;
  if (resultDec > 255 || resultDec < 0) {
    if (resultDec > 255) {
      resultDec = resultDec - 255 - 1;
    } else if (resultDec < 0) {
      resultDec = 255 - Math.abs(resultDec) + 1;
    }
    window.emitter.emit("OVERFLOW");
  }
  if (resultDec === 0) {
    window.emitter.emit("RESULT_ZERO");
  }

  const resultHex = dec2hex(resultDec);

  if (!onlyCalculation) {
    destinationInput.setValue(resultHex); // DEST <= 2OP + DEST
  } else {
    return resultHex;
  }
}

function subt2(onlyCalculation, { op2Input, pcInput, memoryTable, destinationInput }) {
  window.emitter.emit("ALU_OPERATION", "SUB");
  const memPC = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  op2Input.setValue(memPC); // 2OP = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  const op2Dec = hex2dec(op2Input.getValue());
  const destDec = hex2dec(destinationInput.getValue());

  let resultDec = destDec - op2Dec;
  if (resultDec > 255 || resultDec < 0) {
    if (resultDec > 255) {
      resultDec = resultDec - 255 - 1;
    } else if (resultDec < 0) {
      resultDec = 255 - Math.abs(resultDec) + 1;
    }
    window.emitter.emit("OVERFLOW");
  }
  if (resultDec === 0) {
    window.emitter.emit("RESULT_ZERO");
  }

  const resultHex = dec2hex(resultDec);

  if (!onlyCalculation) {
    destinationInput.setValue(resultHex); // DEST <= DEST - 2OP
  } else {
    return resultHex;
  }
}

function cmpt2({
  op2Input,
  pcInput,
  memoryTable,
  destinationInput,
  fzSwitch,
  fcSwitch,
}) {
  window.emitter.emit("ALU_OPERATION", "CMP");
  const subResultHex = subt2(true, {
    // should sub emit event here? because CMP is emiting in the last line
    destinationInput,
    memoryTable,
    op2Input,
    pcInput,
  });

  const subResultDec = hex2dec(subResultHex);
  if (subResultDec > 0) {
    // FZ 0, FC 0
    fzSwitch.setChecked(false);
    fcSwitch.setChecked(false);
  } else if (subResultDec < 0) {
    // FZ 0, FC 1
    fzSwitch.setChecked(false);
    // fcSwitch.setChecked(true);
    fcSwitch.setChecked(false);
  } else {
    // FZ 1, FC 1
    fzSwitch.setChecked(true);
    // fcSwitch.setChecked(true);
    fcSwitch.setChecked(false);
  }
}

function andt2(onlyCalculation, { destinationInput, op2Input, pcInput, memoryTable }) {
  window.emitter.emit("ALU_OPERATION", "AND");
  const memPC = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  op2Input.setValue(memPC); // 2OP = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  const op2Dec = hex2dec(op2Input.getValue());
  const destDec = hex2dec(destinationInput.getValue());

  const andDec = destDec & op2Dec;
  const andHex = dec2hex(andDec);

  if (!onlyCalculation) {
    destinationInput.setValue(andHex); // dest = DEST + 2OP
  } else {
    return andHex;
  }
}

function ort2(onlyCalculation, { destinationInput, op2Input, pcInput, memoryTable }) {
  window.emitter.emit("ALU_OPERATION", "OR");
  const memPC = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  op2Input.setValue(memPC); // 2OP = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  const op2Dec = hex2dec(op2Input.getValue());
  const destDec = hex2dec(destinationInput.getValue());

  const orDec = destDec | op2Dec;
  const orHex = dec2hex(orDec);

  if (!onlyCalculation) {
    destinationInput.setValue(orHex); // dest = DEST + 2OP
  } else {
    return orHex;
  }
}

function xort2(onlyCalculation, { destinationInput, op2Input, pcInput, memoryTable }) {
  window.emitter.emit("ALU_OPERATION", "XOR");
  const memPC = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  op2Input.setValue(memPC); // 2OP = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  const op2Dec = hex2dec(op2Input.getValue());
  const destDec = hex2dec(destinationInput.getValue());

  const xorDec = destDec ^ op2Dec;
  const xorHex = dec2hex(xorDec);

  if (!onlyCalculation) {
    destinationInput.setValue(xorHex); // dest = DEST + 2OP
  } else {
    return xorHex;
  }
}

function lda({
  destinationInput,
  op2Input,
  pcInput,
  memoryTable,
  hInput,
  lInput,
}) {
  const memPC = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  hInput.setValue(memPC); // H = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  const memPC2 = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  lInput.setValue(memPC2); // L = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  const memHL = memoryTable.props.getValue(
    hInput.getValue() + lInput.getValue(),
    true
  );
  op2Input.setValue(memHL); // 2OP = HL
  destinationInput.setValue(op2Input.getValue()); // DEST = 2OP
}

function sta({ op2Input, pcInput, memoryTable, hInput, lInput, accInput }) {
  const memPC = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  hInput.setValue(memPC); // H = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  const memPC2 = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  lInput.setValue(memPC2); // L = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  op2Input.setValue(accInput.getValue(accInput)); // 2OP <= AC
  memoryTable.props.setValue(
    hInput.getValue() + lInput.getValue(),
    op2Input.getValue(),
    true
  ); // MEM(HL) <= 2OP
}

function beq({ pcInput, memoryTable, hInput, lInput, riInput, fzSwitch }) {
  const memPC = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  hInput.setValue(memPC); // H = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  const memPC2 = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  lInput.setValue(memPC2); // L = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  if (fzSwitch.getLastChecked()) {
    const memHL = memoryTable.props.getValue(
      hInput.getValue() + lInput.getValue(),
      true
    ); // MEM(HL)

    riInput.setValue(memHL); // IR <= MEM(HL)

    const hl = hInput.getValue() + lInput.getValue(); // HL

    pcInput.setValue(dec2hex(hex2dec(hl) + 1, 4)); // PC = HL +1
    // BEQ DOESNT EXECUTE THE STEP TWICE!!!!
  } else {
    // THIS IS THE STEP SO NO NEED
    // const memPC = memoryTable.props.getValue(
    //   pcInput.getValue(),
    //   true
    // ); // MEM(PC)
    // riInput.setValue(memPC); // IR <= MEM(PC)
    // pcInput.setValue(
    //   pcInput,
    //   dec2hex(hex2dec(pcInput.getValue()) + 1, 4)
    // ); // PC = PC + 1
  }

  return fzSwitch.getLastChecked();
}

function bc({ pcInput, memoryTable, hInput, lInput, riInput, fcSwitch }) {
  const memPC = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  hInput.setValue(memPC); // H = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  const memPC2 = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  lInput.setValue(memPC2); // L = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  if (fcSwitch.getLastChecked()) {
    const memHL = memoryTable.props.getValue(
      hInput.getValue() + lInput.getValue(),
      true
    ); // MEM(HL)

    riInput.setValue(memHL); // IR <= MEM(HL)

    const hl = hInput.getValue() + lInput.getValue(); // HL

    pcInput.setValue(dec2hex(hex2dec(hl) + 1, 4)); // PC = HL +1
    // BC DOESNT EXECUTE THE STEP TWICE!!!!
  } else {
    // THIS IS THE STEP SO NO NEED
    // const memPC = memoryTable.props.getValue(
    //   pcInput.getValue(),
    //   true
    // ); // MEM(PC)
    // riInput.setValue(memPC); // IR <= MEM(PC)
    // pcInput.setValue(
    //   pcInput,
    //   dec2hex(hex2dec(pcInput.getValue()) + 1, 4)
    // ); // PC = PC + 1
  }

  return fcSwitch.getLastChecked();
}

function jmp({ pcInput, memoryTable, hInput, lInput, riInput }) {
  const memPC = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  hInput.setValue(memPC); // H = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  const memPC2 = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  lInput.setValue(memPC2); // L = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  const memHL = memoryTable.props.getValue(
    hInput.getValue() + lInput.getValue(),
    true
  ); // MEM(HL)

  riInput.setValue(memHL); // IR <= MEM(HL)

  const hl = hInput.getValue() + lInput.getValue(); // HL

  pcInput.setValue(dec2hex(hex2dec(hl) + 1, 4)); // PC = HL +1
  // JMP DOESNT EXECUTE THE STEP TWICE!!!!
}

function cma({ accInput }) {
  accInput.setValue(dec2hex(~hex2dec(accInput.getValue(accInput))));
}

function lfa({ accInput, fzSwitch, fcSwitch }) {
  const bin = `${
    fcSwitch ? (fcSwitch.getLastChecked() ? "1" : "0") : "0"
  }000000${fzSwitch ? (fzSwitch.getLastChecked() ? "1" : "0") : "0"}`;
  const hex = bin2hex(bin);
  accInput.setValue(hex);
}

function sfa({ accInput, fzSwitch, fcSwitch }) {
  const accValueHex = accInput.getValue(accInput); // ACC
  const bin = hex2bin(accValueHex);
  const fc = bin.substring(0, 1);
  const fz = bin.substring(bin.length - 1, bin.length);
  if (fc === "1") {
    fcSwitch.setChecked(true);
  } else {
    fcSwitch.setChecked(false);
  }

  if (fz === "1") {
    fzSwitch.setChecked(true);
  } else {
    fzSwitch.setChecked(false);
  }
}

function stax({
  accInput,
  rdInput,
  reInput,
  hInput,
  lInput,
  op2Input,
  memoryTable,
}) {
  hInput.setValue(rdInput.getValue(rdInput));
  lInput.setValue(reInput.getValue(reInput));
  op2Input.setValue(accInput.getValue(accInput));
  memoryTable.props.setValue(
    hInput.getValue() + lInput.getValue(),
    op2Input.getValue(),
    true
  ); // MEM(HL) <= 2OP
}

function ldax({
  accInput,
  rdInput,
  reInput,
  hInput,
  lInput,
  op2Input,
  memoryTable,
}) {
  hInput.setValue(rdInput.getValue(rdInput));
  lInput.setValue(reInput.getValue(reInput));
  const memHL = memoryTable.props.getValue(
    hInput.getValue() + lInput.getValue(),
    true
  ); // MEM(HL)
  op2Input.setValue(memHL);
  accInput.setValue(op2Input.getValue());
}

function push({ accInput, spInput, memoryTable }) {
  const spValue = spInput.getValue();

  const newValueDec = hex2dec(spValue) - 1;
  let newValueHex = dec2hex(newValueDec, 4);

  if (newValueDec === -1) {
    newValueHex = "FFFF";
  }
  spInput.setValue(newValueHex); // SP <= SP - 1

  memoryTable.props.setValue(newValueHex, accInput.getValue(accInput), true); // MEM(SP) <= ACC
}

function pop({ accInput, spInput, memoryTable }) {
  const memSP = memoryTable.props.getValue(spInput.getValue(), true); // MEM(SP)
  accInput.setValue(memSP); // ACC <= MEM(SP)

  const newValueDec = hex2dec(spInput.getValue()) + 1;
  let newValueHex = dec2hex(newValueDec, 4);

  if (newValueDec === 65536) {
    newValueHex = "0000";
  }

  spInput.setValue(newValueHex); // SP <= SP + 1
}

function call({ pcInput, memoryTable, hInput, lInput, spInput }) {
  const memPC = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  hInput.setValue(memPC); // H = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  const memPC2 = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  lInput.setValue(memPC2); // L = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  const newValueDec = hex2dec(spInput.getValue()) - 1;
  let newValueHex = dec2hex(newValueDec, 4);

  if (newValueDec === -1) {
    newValueHex = "FFFF";
  }
  spInput.setValue(newValueHex);

  memoryTable.props.setValue(
    spInput.getValue(),
    pcInput.getValue().substring(0, 2),
    true
  ); // MEM(SP) <= PCH

  const newValueDec2 = hex2dec(spInput.getValue()) - 1;
  let newValueHex2 = dec2hex(newValueDec2, 4);

  if (newValueDec2 === -1) {
    newValueHex2 = "FFFF";
  }
  spInput.setValue(newValueHex2);

  memoryTable.props.setValue(
    spInput.getValue(),
    pcInput.getValue().substring(2, 4),
    true
  ); // MEM(SP) <= PCL

  pcInput.setValue(hInput.getValue() + lInput.getValue()); // PC <= HL
}

function inisp({ pcInput, memoryTable, hInput, lInput, spInput }) {
  const memPC = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  hInput.setValue(memPC); // H = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1

  const memPC2 = memoryTable.props.getValue(pcInput.getValue(), true); // MEM(PC)
  lInput.setValue(memPC2); // L = MEM(PC)
  pcInput.setValue(dec2hex(hex2dec(pcInput.getValue()) + 1, 4)); // PC = PC + 1
  spInput.setValue(hInput.getValue() + lInput.getValue()); // PC <= HL
}

function ret({ memoryTable, spInput, pclInput, pchInput }) {
  const memSP = memoryTable.props.getValue(spInput.getValue(), true); // MEM(SP)

  pclInput.setValue(memSP); // PCL <= MEM(SP)

  const newValueDec = hex2dec(spInput.getValue()) + 1;
  let newValueHex = dec2hex(newValueDec, 4);

  if (newValueDec === 65536) {
    newValueHex = "0000";
  }

  spInput.setValue(newValueHex); // SP <= SP + 1

  const memSP2 = memoryTable.props.getValue(newValueHex, true); // MEM(SP)

  pchInput.setValue(memSP2); // PCL <= MEM(SP)

  const newValueDec2 = hex2dec(spInput.getValue()) + 1;
  let newValueHex2 = dec2hex(newValueDec2, 4);

  if (newValueDec2 === 65536) {
    newValueHex2 = "0000";
  }

  spInput.setValue(newValueHex2); // SP <= SP + 1
}

function intr({
  intaSwitch,
  spInput,
  pchInput,
  pclInput,
  memoryTable,
  fzSwitch,
  fcSwitch,
}) {
  intaSwitch.setChecked(true); // INTA <= '1'

  const spValue = spInput.getValue();
  const newValueDec = hex2dec(spValue) - 1;
  let newValueHex = dec2hex(newValueDec, 4);

  if (newValueDec === -1) {
    newValueHex = "FFFF";
  }
  spInput.setValue(newValueHex); // SP <= SP - 1

  const bin = `${
    fcSwitch ? (fcSwitch.getLastChecked() ? "1" : "0") : "0"
  }000000${fzSwitch ? (fzSwitch.getLastChecked() ? "1" : "0") : "0"}`;
  const hex = bin2hex(bin, 4);

  memoryTable.props.setValue(hex, true); // MEM(SP) <= FLAGS

  const spValue2 = spInput.getValue();
  const newValueDec2 = hex2dec(spValue2) - 1;
  let newValueHex2 = dec2hex(newValueDec2, 4);

  if (newValueDec2 === -1) {
    newValueHex2 = "FFFF";
  }
  spInput.setValue(newValueHex2); // SP <= SP - 1

  memoryTable.props.setValue(spInput.getValue(), pchInput.getValue(), true); // MEM(SP) <= PCH

  const spValue3 = spInput.getValue();
  const newValueDec3 = hex2dec(spValue3) - 1;
  let newValueHex3 = dec2hex(newValueDec3, 4);

  if (newValueDec3 === -1) {
    newValueHex3 = "FFFF";
  }
  spInput.setValue(newValueHex3); // SP <= SP - 1

  memoryTable.props.setValue(spInput.getValue(), pclInput.getValue(), true); // MEM(SP) <= PCL

  pchInput.setValue("FF"); // PCH <= "FF"
  pclInput.setValue("FC"); // PCL <= "FC"

  window.emitter.emit("PC_UPDATED", { pclValue: "FF", pchValue: "FC" });
}

function iret({
  pclInput,
  pchInput,
  spInput,
  eoiSwitch,
  memoryTable,
  fcSwitch,
  fzSwitch,
}) {
  const memSP = memoryTable.props.getValue(spInput.getValue(), true); // MEM(SP)

  pclInput.setValue(memSP); // PCL <= MEM(SP)

  const newValueDec = hex2dec(spInput.getValue()) + 1;
  let newValueHex = dec2hex(newValueDec, 4);

  if (newValueDec === 65536) {
    newValueHex = "0000";
  }

  spInput.setValue(newValueHex); // SP <= SP + 1

  const memSP2 = memoryTable.props.getValue(spInput.getValue(), true); // MEM(SP)
  pchInput.setValue(memSP2); // PCH <= MEM(SP)

  const newValueDec2 = hex2dec(spInput.getValue()) + 1;
  let newValueHex2 = dec2hex(newValueDec2, 4);

  if (newValueDec2 === 65536) {
    newValueHex2 = "0000";
  }
  spInput.setValue(newValueHex2); // SP <= SP + 1

  const memSP3 = memoryTable.props.getValue(spInput.getValue(), true); // MEM(SP)

  const bin = hex2bin(memSP3);
  const fc = bin.substring(0, 1);
  const fz = bin.substring(bin.length - 1, bin.length);
  if (fc === "1") {
    fcSwitch.setChecked(true);
  } else {
    fcSwitch.setChecked(false);
  }

  if (fz === "1") {
    fzSwitch.setChecked(true);
  } else {
    fzSwitch.setChecked(false);
  }

  const newValueDec3 = hex2dec(spInput.getValue()) + 1;
  let newValueHex3 = dec2hex(newValueDec3, 4);

  if (newValueDec3 === 65536) {
    newValueHex3 = "0000";
  }
  spInput.setValue(newValueHex3); // SP <= SP + 1

  eoiSwitch.setChecked(true); // EOI <= '1'
}

module.exports = {
  step,
  mv,
  sub,
  cmp,
  and,
  or,
  xor,
  inc,
  add,
  mvt2,
  addt2,
  subt2,
  cmpt2,
  andt2,
  ort2,
  xort2,
  lda,
  sta,
  beq,
  bc,
  jmp,
  cma,
  lfa,
  sfa,
  stax,
  ldax,
  push,
  pop,
  call,
  inisp,
  ret,
  intr,
  iret,
};
