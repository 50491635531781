import { Input as InputAntd } from 'antd';
import React from 'react';

export default class Input extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = { value: props.defaultValue };

        this.antdInput = React.createRef();
    }

    props = {
        setValue: this.setValue,
        getValue: this.getValue
    }

    highlight(color = "#FFF2A7") {
        if (this.antdInput.current) {
            this.antdInput.current.input.style.backgroundColor = color;
        }

    }
    setValue(newValue) {
        this.setState({ value: newValue });
        window.emitter.emit("INPUT_ON_CHANGE", this);
    }
    getValue(emit) {
        if (!emit) {
            window.emitter.emit("INPUT_GET_VALUE", this);
        }
        return this.state.value;
    }

    onChange(e) {
        this.props.onChange && this.props.onChange(e);
    }

    render() {
        return <InputAntd ref={this.antdInput} value={this.props.value || this.state.value} onChange={this.onChange.bind(this)} addonBefore={this.props.addonBefore} />
    }

}
