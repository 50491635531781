import React from 'react';
const BoardContext = React.createContext({});

export const BoardContextProvider = BoardContext.Provider;

export default BoardContext;

export const createStructure = () => {
    const structure = {
        registers: { rb: undefined, rc: undefined, rd: undefined, re: undefined, accum: undefined, ri: undefined, pc: undefined, pcl: undefined, pch: undefined, h: undefined, l: undefined, sp: undefined, op2: undefined },
        statuses: {
            regCar: undefined,
            selReg: undefined,
            regBus: undefined,
            acCAR: undefined,
            acBus: undefined,
            car2: undefined,
            selAlu: undefined,
            aluBus: undefined,
            hCar: undefined,
            lCar: undefined,
            memBus: undefined,
            memRw: undefined,
            pcCar: undefined,
            selDir: undefined,
            pchCar: undefined,
            pclCar: undefined,
            pchBus: undefined,
            pclBus: undefined,
            spCar: undefined,
            flBus: undefined,
            riCar: undefined,
            flCar: undefined,
            selFl: undefined,
            inta: undefined,
            eoi: undefined,
            intr: undefined,
        },
        flags: { fc: undefined, fz: undefined, },
        console: undefined,
        memory: undefined,
    };

    const assignRefs = (obj) => {
        Object.keys(obj).forEach(key => {

            if (typeof obj[key] === 'object') {
                assignRefs(obj[key])
            } else {
                obj[key] = React.useRef();
            }
        })
    }

    assignRefs(structure);

    return structure;
}