import React, { useContext } from 'react';
import Input from './Input';

import { events } from '../utils/input';
import BoardContext from '../contexts/BoardContext';
import Switch from './Switch';

const registerStyle = {
    position: "absolute",
    width: 74
};

const labelStyle = {
    backgroundColor: "black",
    color: "white",
    marginBottom: 0,
}

const Registers = () => {
    const { registers: { rb: rbRef, rc: rcRef, rd: rdRef, re: reRef }, statuses: { regCar: regCarRef, selReg: selRegRef, regBus: regBusRef } } = useContext(BoardContext);

    return (
        <div id="registers" style={{ position: "absolute", left: 180, top: 108, }}>
            <div style={{ ...registerStyle, marginLeft: 5, marginTop: 10, }}>
                <p style={labelStyle}>
                    B
                 </p>
                <Input readOnly {...events} ref={rbRef} defaultValue="BB" />
            </div>

            <div style={{ ...registerStyle, marginLeft: 80, marginTop: 10 }}>
                <p style={labelStyle}>
                    C
                </p>
                <Input readOnly {...events} ref={rcRef} defaultValue="CC" />
            </div>

            <div style={{ ...registerStyle, marginLeft: 5, marginTop: 73 }}>
                <p
                    style={labelStyle}
                >
                    D
          </p>
                <Input readOnly {...events} ref={rdRef} defaultValue="DD" />
            </div>

            <div style={{ ...registerStyle, marginLeft: 80, marginTop: 73 }}>
                <p
                    style={labelStyle}
                >
                    E
          </p>
                <Input readOnly {...events} ref={reRef} defaultValue="EE" />
            </div>


            <div style={{ ...registerStyle, marginLeft: 175, marginTop: -10, width: 50 }}>
                <p
                    style={labelStyle}
                >
                    Reg<sub>car</sub>
                </p>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={regCarRef} />
            </div>

            <div style={{ ...registerStyle, marginLeft: 47, marginTop: 150, width: 50 }}>
                <p
                    style={labelStyle}
                >
                    <sub>sel</sub>Reg
                </p>
                <Input readOnly {...events} ref={selRegRef} defaultValue="00" />
            </div>

            <div style={{ ...registerStyle, marginLeft: 155, marginTop: -88, width: 50 }}>
                <p
                    style={labelStyle}
                >
                    Reg<sub>bus</sub>
                </p>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={regBusRef} />
            </div>
        </div >
    );
}

export default Registers;