import { animations } from 'react-animation'
import React from 'react';
import icon from '../assets/img/icon.png'

import { Typography } from 'antd';

import Loader from "react-loader-spinner";


const { Title } = Typography;

const Welcome = ({ time = 2000, onReady }) => {
    const [shown, setShown] = React.useState(true);
    setTimeout(() => {
        setShown(false);
        onReady()
    }, time);
    return (
        <div style={{ position: 'absolute', left: 0, top: 0, height: '100%', width: '100%', backgroundColor: 'white', zIndex: 100, animation: shown ? 'unset' : animations.fadeOut }}>
            <img alt="icon" src={icon} style={{ height: '50%', position: 'absolute', top: 0, bottom: 0, margin: 'auto', right: 0, left: 0, animation: shown ? animations.fadeIn : animations.bounceOut }} />
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                <Title style={{ padding: 10 }}>
                    M++ SIMULATOR
            </Title>
            </div>
            <Loader
                type="TailSpin"
                color="black"
                height={30}
                width={30}
                style={{ position: 'absolute', right: 0, bottom: 0, padding: 10 }}
            />
            <Title level={5} style={{ padding: 10, position: 'absolute', bottom: 0, left: 0 }}>Powered by <a href="https://nullx.me" target="_blanc">@nullx</a></Title>
        </div>
    );
}

export default Welcome;