function hex2bin(hex) {
  return parseInt(hex, 16).toString(2).padStart(8, '0');
}

function bin2hex(bin, padN = 2) {
  return parseInt(bin, 2).toString(16).padStart(padN, '0').toUpperCase();
}

function hex2dec(hex) {
  return parseInt(hex, 16);
}

function dec2hex(dec, padN = 2) {
  return dec.toString(16).padStart(padN, '0').toUpperCase();
}

function dec2bin(dec) {
  return parseInt(dec, 10).toString(2);
}

function bin2dec(bin) {
  return parseInt(bin, 2);
}

module.exports = {
  hex2bin, bin2hex, hex2dec, dec2hex, dec2bin, bin2dec,
};
