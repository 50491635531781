import React, { useContext } from 'react';
import Input from './Input';

import { events } from '../utils/input';
import BoardContext from '../contexts/BoardContext';
import Switch from './Switch';
const registerStyle = {
    position: "absolute",
    width: 50
};

const labelStyle = {
    backgroundColor: "black",
    color: "white",
    marginBottom: 0,
}

const UnitControl = () => {
    const { registers: { ri: riRef }, statuses: { riCar: riCarRef, flBus: flBusRef } } = useContext(BoardContext);

    return (
        <div id="unit-control" style={{ position: "absolute", left: 700, top: 772 }}>
            <div style={{ ...registerStyle, marginLeft: -505, marginTop: -208, width: 85 }}>
                <Input readOnly {...events} ref={riRef} addonBefore="RI" defaultValue="10" />
            </div>

            <div style={{ ...registerStyle, marginLeft: -360, marginTop: -215, }}>
                <p style={labelStyle}>
                    RI<sub>car</sub>
                </p>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={riCarRef} />
            </div>

            <div style={{ ...registerStyle, marginLeft: -655, marginTop: -660, }}>
                <p style={labelStyle}>
                    FL<sub>bus</sub>
                </p>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={flBusRef} />
            </div>
        </div >
    );
}

export default UnitControl;