
function resetSignals(...signals) {
  for (let i = 0; i < signals.length; i++) {
    const signal = signals[i];

    if (signal.isChecked()) {
      signal.setChecked(false);
    }
  }
}

module.exports = {
  resetSignals,
};
