import React, { useContext } from 'react';
import Input from './Input';
import Switch from './Switch';

import { events } from '../utils/input';
import { dec2hex, hex2dec } from '../utils/operations';
import { Column, Table, AutoSizer } from "react-virtualized";
import BoardContext from '../contexts/BoardContext';
// import _ from 'lodash';

window.directions = [];

for (let i = 0; i <= 65535; i++) {
    const randomDecimal = Math.floor(Math.random() * 255) + 1;
    window.directions.push({ dir: dec2hex(i, 4), value: dec2hex(randomDecimal) });
}

window.loadOperations = (startDir, operations) => {
    let j = 0;
    let startIndex;
    for (let i = 0; i < window.directions.length; i++) {
        const el = window.directions[i];
        if (el.dir === startDir) {
            startIndex = i;
            break;
        }
    }
    if (startIndex + operations.length > window.directions.length) {
        throw new Error('ERR_MEMORY_MAX_VALUE_RAISED');
    }

    for (let i = startIndex; i < startIndex + operations.length; i++) {
        const direction = window.directions[i];
        direction.value = operations[j];
        j++;
    }
}

const registerStyle = {
    position: "absolute",
    width: 50
};

const labelStyle = {
    backgroundColor: "black",
    color: "white",
    marginBottom: 0,
}

const columns = [
    {
        title: 'Dir',
        dataIndex: 'dir',
        key: 'dir',
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
    }
];


const VirtualTable = React.forwardRef(({ dataSource, pclRef, pchRef }, ref) => {
    return (
        <AutoSizer>
            {({ height, width }) => {
                return (
                    <Table
                        width={width}
                        height={height}
                        headerHeight={20}
                        rowHeight={35}
                        scrollToAlignment="center"
                        ref={ref}
                        getValue={(requestDir, s) => {
                            if (s) {
                                window.emitter.emit('MEMORY_GET_VALUE', { dir: requestDir });
                            }
                            const found = dataSource.find(({ dir }) => dir === requestDir);
                            if (!found) {
                                throw new Error('ERR_MEMORY_NO_SUCH_DIRECTION');
                            }
                            return found.value;
                        }}

                        setValue={(requestDir, value, s) => {
                            if (s) {
                                window.emitter.emit('MEMORY_SET_VALUE', { dir: requestDir });
                            }
                            const found = dataSource.find(({ dir }) => dir === requestDir);
                            if (!found) {
                                throw new Error('ERR_MEMORY_NO_SUCH_DIRECTION');
                            }
                            found.value = value;
                        }}

                        rowStyle={({ index }) => {
                            const styles = {};
                            if (index < 0) return {};

                            const pcValue = pclRef.current && pchRef.current ? pchRef.current.state.value + pclRef.current.state.value : '0000';

                            const pcIndex = hex2dec(pcValue);

                            if (index === pcIndex) {
                                styles.backgroundColor = '#dedede';
                            }
                            return styles;
                        }}

                        containerStyle={{ backgroundColor: 'white' }}
                        rowCount={dataSource.length}
                        rowGetter={({ index }) => dataSource[index]}
                    >
                        <Column label="Dir" dataKey="dir" width={200} />
                        <Column width={200} label="Value" dataKey="value" />
                    </Table>
                )
            }}
        </AutoSizer>
    )
});

const Memory = () => {
    const { memory: memoryRef, registers: { h: hRef, l: lRef, pch: pchRef, pcl: pclRef, sp: spRef }, statuses: { memBus: memBusRef, memRw: memRwRef, hCar: hCarRef, lCar: lCarRef, pcCar: pcCarRef, selDir: selDirRef, pchBus: pchBusRef, pclBus: pclBusRef, pchCar: pchCarRef, pclCar: pclCarRef, spCar: spCarRef } } = useContext(BoardContext);

    const [goTo, setGoTo] = React.useState('0000');

    React.useEffect(() => {
        if (!goTo || goTo.length !== 4) return;
        const memoryTable = memoryRef.current;
        if (!memoryTable) return;

        memoryTable.scrollToRow(hex2dec(goTo));
    }, [goTo, memoryRef])
    return (
        <div id="memory" style={{ position: "absolute", left: 768, top: 576 }}>
            <div style={{ ...registerStyle, marginLeft: -3, marginTop: -7, width: 150 }}>
                <Input {...events} ref={hRef} addonBefore="GO TO" value={goTo} onChange={(e) => {
                    if (e.target.value.length <= 4) {
                        setGoTo(e.target.value);
                    }
                }} />
            </div>
            <div style={{ width: 285, height: 168, top: 28, left: -1 }}>
                <VirtualTable
                    ref={memoryRef}
                    columns={columns}
                    dataSource={window.directions}
                    height={100}
                    pchRef={pchRef}
                    pclRef={pclRef}

                />
            </div>
            <div style={{ ...registerStyle, marginLeft: -110, marginTop: -45, }}>
                <p style={labelStyle}>
                    L/E
                </p>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={memRwRef} />
            </div>

            <div style={{ ...registerStyle, marginLeft: 344, marginTop: -503, width: 98 }}>
                <Input readOnly {...events} ref={hRef} addonBefore="H" defaultValue="1F"/>
            </div>

            <div style={{ ...registerStyle, marginLeft: 442, marginTop: -503, width: 105 }}>
                <Input readOnly {...events} ref={lRef} addonBefore="L" defaultValue="45" />
            </div>

            {/* <div style={{ ...registerStyle, marginLeft: 47, marginTop: -498, width: 86, display: 'none' }}> 
                <Input readOnly {...events} ref={pcRef} addonBefore="PC" />
            </div> */}

            <div style={{ ...registerStyle, marginLeft: 570, marginTop: -650, }}>
                <p style={labelStyle}>
                    MEM<sub>bus</sub>
                </p>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={memBusRef} />
            </div>

            <div style={{ ...registerStyle, marginLeft: 360, marginTop: -440, }}>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={hCarRef} />
                <p style={labelStyle}>
                    H<sub>car</sub>
                </p>
            </div>

            <div style={{ ...registerStyle, marginLeft: 480, marginTop: -440, }}>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={lCarRef} />
                <p style={labelStyle}>
                    L<sub>car</sub>
                </p>
            </div>

            <div style={{ ...registerStyle, marginLeft: 19, marginTop: -465, }}>
                <Switch style={{ pointerEvents: 'none', display: 'none', }} ref={pcCarRef} />
                <p style={labelStyle}>
                    PC<sub>car</sub>
                </p>
            </div>

            <div style={{ ...registerStyle, marginLeft: 260, marginTop: -294, }}>
                <p style={labelStyle}>
                    <sub>sel</sub>DIR
                </p>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={selDirRef} />
            </div>
            {/* new */}
            <div style={{ ...registerStyle, marginLeft: -70, marginTop: -628, }}>
                <p style={labelStyle}>
                    PCH<sub>bus</sub>
                </p>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={pchBusRef} />
            </div>
            <div style={{ ...registerStyle, marginLeft: 220, marginTop: -660, }}>
                <p style={labelStyle}>
                    PCL<sub>bus</sub>
                </p>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={pclBusRef} />
            </div>

            <div style={{ ...registerStyle, marginLeft: -52, marginTop: -498, width: 98 }}>
                <Input readOnly {...events} ref={pchRef} addonBefore="PCH" defaultValue="00" />
            </div>

            <div style={{ ...registerStyle, marginLeft: 134, marginTop: -498, width: 91 }}>
                <Input readOnly {...events} ref={pclRef} addonBefore="PCL" defaultValue="00" />
            </div>

            <div style={{ ...registerStyle, marginLeft: 240, marginTop: -466, width: 100 }}>
                <Input readOnly {...events} ref={spRef} addonBefore="SP" defaultValue="0000" />
            </div>

            {/* <div style={{ ...registerStyle, marginLeft: 240, marginTop: -466, width: 91 }}>
                <Input readOnly {...events} ref={spRef} addonBefore="SP" />
            </div> */}

            <div style={{ ...registerStyle, marginLeft: -52, marginTop: -465, }}>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={pchCarRef} />
                <p style={labelStyle}>
                    PCH<sub>car</sub>
                </p>
            </div>

            <div style={{ ...registerStyle, marginLeft: 128, marginTop: -465, }}>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={pclCarRef} />
                <p style={labelStyle}>
                    PCL<sub>car</sub>
                </p>
            </div>

            <div style={{ ...registerStyle, marginLeft: 230, marginTop: -530, }}>
                <p style={labelStyle}>
                    SP<sub>car</sub>
                </p>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={spCarRef} />
            </div>
        </div>
    )
}

export default Memory;
