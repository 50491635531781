import React, { useContext } from 'react';
import Input from './Input';

import { events } from '../utils/input';
import BoardContext from '../contexts/BoardContext';
import Switch from './Switch';

const registerStyle = {
    position: "absolute",
    width: 50
};

const labelStyle = {
    backgroundColor: "black",
    color: "white",
    marginBottom: 0,
}

const ALU = () => {
    const { registers: { accum: accumRef, op2: op2Ref }, statuses: { acCAR: acCarRef, acBus: acBusRef, car2: car2Ref, selAlu: selAluRef, aluBus: aluBusRef } } = useContext(BoardContext);

    return (
        <div id="alu" style={{ position: "absolute", left: 390, top: 50 }}>
            <div style={{ ...registerStyle, marginLeft: 27, marginTop: 65, width: 128 }}>
                <Input readOnly {...events} ref={accumRef} addonBefore="ACCUM" defaultValue="FF" />
            </div>

            <div style={{ ...registerStyle, marginLeft: 144, marginTop: 24, width: 119 }}>
                <Input readOnly {...events} ref={op2Ref} addonBefore="2OP" defaultValue="2F" />
            </div>

            <div style={{ ...registerStyle, marginLeft: 145, marginTop: -35, }}>
                <p style={labelStyle}>
                    AC<sub>bus</sub>
                </p>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={acBusRef} />
            </div>

            <div style={{ ...registerStyle, marginLeft: 220, marginTop: -30, }}>
                <p style={labelStyle}>
                    2<sub>car</sub>
                </p>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={car2Ref} />
            </div>
            <div style={{ ...registerStyle, marginLeft: 25, marginTop: -30, }}>
                <p style={labelStyle}>
                    AC<sub>car</sub>
                </p>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={acCarRef} />
            </div>

            <div style={{ ...registerStyle, marginLeft: 205, marginTop: 105 }}>
                <p style={labelStyle}>
                    <sub>sel</sub>ALU
                </p>
                <Input readOnly {...events} ref={selAluRef} defaultValue="000" />
            </div>

            <div style={{ ...registerStyle, marginLeft: 308, marginTop: 7, }}>
                <p style={labelStyle}>
                    ALU<sub>bus</sub>
                </p>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={aluBusRef} />
            </div>
        </div>
    );
}

export default ALU;