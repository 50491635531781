import Terminal from 'react-console-emulator'
import Draggable from 'react-draggable';

const Console = ({ consoleRef }) => {
    return (
        // <div style={{ position: 'absolute', top: 500, left: 10, width: 200, height: 50, minHeight: 'unset', pointerEvents: 'stroke' }}>
        <Draggable defaultPosition={{ x: 10, y: -400 }}>
            <div style={{ zIndex: 99999, position: 'relative', maxWidth: 200, maxHeight: 180}}>
                <Terminal
                    style={{ minHeight: 'unset', maxHeight: 180, width: 200, overflow: 'scroll', scrollbarWidth: 'none' }}
                    commands={{
                        OP: {
                            description: '',
                            fn: function (...args) {
                                return null;
                            }
                        },
                        OPUN: {
                            description: '',
                            fn: function (...args) {
                                return null;
                            }
                        }
                    }}
                    welcomeMessage='M++ SIMULATOR'
                    readOnly
                    ref={consoleRef}
                />
            </div>

        </Draggable>
        // </div>
    )
}

export default Console;