import { Button, Typography } from 'antd';
import React from 'react';
import {
    SmileTwoTone,
    CloseCircleFilled,
} from "@ant-design/icons";
import Sheet from 'react-modal-sheet';
import GoogleAnalytics from 'react-ga';


const { Title } = Typography;

const Author = () => {
    const [isOpen, setOpen] = React.useState(false);
    
    React.useEffect(() => {
        if (isOpen) {
            GoogleAnalytics.pageview('/about');
        }
    }, [isOpen]);

    return (<>
        <div style={{ position: 'absolute', top: 0, left: 0, padding: 10, }}>
            <Button icon={<SmileTwoTone />} onClick={() => setOpen(true)}>About</Button>
        </div>
        <Sheet isOpen={isOpen} onClose={() => setOpen(false)} snapPoints={[600, 0]}>
            <Sheet.Container>
                <Sheet.Header />
                <Title style={{ padding: 10 }}>Author <a href="https://nullx.me" target="_BLANC">@nullx</a> <Button type="dashed" size={20} icon={<CloseCircleFilled />} onClick={() => setOpen(false)}>
                </Button>
                </Title>
                <Sheet.Content>

                    <div style={{ padding: 15, width: 800, height: 200 }}>

                        <div>
                            <h2> Hi, I'm Jon Lara! <img src="https://media.giphy.com/media/mGcNjsfWAjY5AEZNw6/giphy.gif" width="50" alt="gif" /></h2>
                            <img src="https://i.ibb.co/b719GQ0/yellow-Jon.jpg" width="230" align="right" alt="jon" />
                            <p></p>
                            <p><em>Backend developer at <a href="https://rebellionpay.com">Rebellion pay</a><img src="https://media.giphy.com/media/WUlplcMpOCEmTGBtBW/giphy.gif" width="30" alt="gif" /> <br />Studying at <a href="https://deusto.es">University of Deusto</a>
                            </em>
                            </p>
                            <p><a href="https://twitter.com/nullxme"><img src="https://img.shields.io/twitter/follow/nullxme?style=social" alt="Twitter: nullxme" /></a>
                                <a href="https://www.linkedin.com/in/jon-lara-trigo-965ab898/"><img src="https://img.shields.io/badge/-Jon%20Lara-blue?style=flat-square&amp;logo=Linkedin&amp;logoColor=white&amp;link=https://www.linkedin.com/in/jon-lara-trigo-965ab898/" alt="Linkedin: Jon Lara" /></a>
                                <a href="https://github.com/nullxx"><img src="https://img.shields.io/github/followers/nullxx?label=follow&amp;style=social" alt="GitHub nullxx" /></a>
                            </p>
                            <p><img src="https://media3.giphy.com/media/USV0ym3bVWQJJmNu3N/giphy.gif" width="60" alt="gif" /> <em><b>I love coding!</b> :)</em></p>

                            <div style={{ overflow: 'hidden' }}>
                                <img src="https://github-readme-stats.vercel.app/api?username=nullxx&show_icons=true&theme=radical" alt="Jon Lara Github Stats" />
                            </div>
                        </div>
                    </div>
                </Sheet.Content>
            </Sheet.Container>

            <Sheet.Backdrop />
        </Sheet>
    </>
    );
};

export default Author;