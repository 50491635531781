import React from 'react';
export default class Switch extends React.Component {
    constructor(props) {
        super(props);
        this.state = { valuesHistory: [] };
        
        this.checkboxInput = React.createRef();

        if (props.name) {
            this.setName(props.name);
        }
    }

    setName(name) {
        if (this.checkboxInput.current) {
            this.checkboxInput.current.setAttribute('name', name);
            this.setState({ name });
        }
    }

    getName() {
        return this.state.name;
    }

    clone() {
        if (this.checkboxInput.current) {
            return this.checkboxInput.current.cloneNode(true);
        }
    }

    setChecked(value) {
        if (this.checkboxInput.current) {
            this.checkboxInput.current.checked = value;

            this.setState({ valuesHistory: [...this.state.valuesHistory, value] });
        }
    }

    isChecked() {
        if (this.checkboxInput.current) {
            return this.checkboxInput.current.checked;
        }

        return false;
    }

    getLastChecked() {
        return this.state.valuesHistory.slice(this.state.valuesHistory.length - 2, this.state.valuesHistory.length - 1)[0];
    }

    render() {
        return <input ref={this.checkboxInput} type="checkbox" autoFocus={false} {...this.props}></input>
    }
}