import React, { useContext } from 'react';
import { Button } from 'antd';

import BoardContext from '../contexts/BoardContext';
import { StopOutlined } from '@ant-design/icons';
import Switch from './Switch';
import CPU from '../lib/cpu';

const registerStyle = {
    position: "absolute",
    width: 50
};

const labelStyle = {
    backgroundColor: "black",
    color: "white",
    marginBottom: 0,
}

const Clock = () => {
    const { memory, registers: { pch: pchRef, pcl: pclRef, sp: spRef }, statuses: { inta: intaRef, eoi: eoiRef, intr: intrRef } } = useContext(BoardContext);

    return (
        <div id="alu" style={{ position: "absolute", left: 390, top: 700 }}>
            <div style={{ ...registerStyle, marginLeft: 260, marginTop: -126, width: 60, textAlign: 'right' }}>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={intaRef} />
                <p style={labelStyle}>
                    INTA
                </p>
            </div>
            <div style={{ ...registerStyle, marginLeft: 70, marginTop: -44, width: 60, textAlign: 'right' }}>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={eoiRef} />
                <p style={labelStyle}>
                    EOI
                </p>
            </div>

            <div style={{ ...registerStyle, marginLeft: 320, marginTop: -170, width: 60 }}>
                <p style={labelStyle}>
                    INTR
                </p>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={intrRef} />
            </div>
            <div style={{ ...registerStyle, marginLeft: 0, marginTop: 50, width: 60 }}>
                <Button
                    onClick={() => {
                        CPU.intr({ intaSwitch: intaRef.current, memoryTable: memory.current, pchInput: pchRef.current, pclInput: pclRef.current, spInput: spRef.current });
                        window.emitter.emit("PROGRAM_STOPPED");
                    }}
                    icon={<StopOutlined />}
                >Interrupt</Button>
            </div>
        </div>
    );
}

export default Clock;