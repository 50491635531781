import React from 'react';
import { PlusOutlined } from '@ant-design/icons';

const examples = [
  {
    name: 'SUM ACC UNTIL ACC=5',
    code: [
      'MOV 00, AC',
      'ETI2 INC AC',
      'CMP 05',
      'BEQ ETI1',
      'JMP ETI2',
      'ETI1 FIN',
    ],
    icon: <PlusOutlined />,
  },
];

export default examples;
