import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css';

import 'antd/dist/antd.css';
import 'react-virtualized/styles.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/keymap/sublime';

import 'codemirror/theme/ayu-mirage.css';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />,
  </React.StrictMode>,
  document.getElementById('root'),
);
