import React, { useContext } from 'react';
import Switch from './Switch';

import BoardContext from '../contexts/BoardContext';

const registerStyle = {
    position: "absolute",
    width: 50
};

const labelStyle = {
    backgroundColor: "black",
    color: "white",
    marginBottom: 0,
}

const Flags = () => {
    const { flags: { fc: fcRef, fz: fzRef }, statuses: { flCar: flCarRef, selFl: selFlRef } } = useContext(BoardContext);

    return (
        <div id="flags" style={{ position: "absolute", left: 480, top: 410 }}>
            <div style={{ ...registerStyle, marginLeft: 10, marginTop: 8, }}>
                <p style={labelStyle}>
                    FL<sub>car</sub>
                </p>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={flCarRef} />
            </div>

            <div style={{ ...registerStyle, marginLeft: -100, marginTop: -165, }}>
                <p style={labelStyle}>
                    <sub>sel</sub>FL
                </p>
                <Switch style={{ pointerEvents: 'none', display: 'none' }} ref={selFlRef} />
            </div>

            <div style={{ ...registerStyle, marginLeft: -95, marginTop: -215, width: 60 }}>
                <p style={labelStyle}>
                    FC
                </p>
                <Switch style={{ pointerEvents: 'none' }} ref={fcRef} name="fc" />
            </div>

            <div style={{ ...registerStyle, marginLeft: -40, marginTop: -160, width: 60 }}>
                <p style={labelStyle}>
                    FZ
                </p>
                <Switch style={{ pointerEvents: 'none' }} ref={fzRef} name="fz" />
            </div>
        </div >
    );
}

export default Flags;