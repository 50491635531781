import CodeMirror from '@uiw/react-codemirror';
import React from 'react';

import 'codemirror/keymap/sublime';
import 'codemirror/theme/ayu-mirage.css';
import "codemirror/mode/sql/sql";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/sql-hint";
import "codemirror/addon/hint/show-hint.css";
import { dec2hex, hex2dec } from '../utils/operations';



export const CodeEditor = React.forwardRef(({ code, onChange, disabled, lineNumberStart, width }, ref) => (
  <div style={{ ...{ height: 150, width } }}>
    <CodeMirror
      ref={ref}
      value={code}
      options={{
        theme: 'ayu-mirage',
        keyMap: 'sublime',
        mode: 'text/plain',
        lineWrapping: true,
        smartIndent: true,
        lineNumbers: true,
        foldGutter: true,
        readOnly: disabled,
        autocapitalize: true,
        allowDropFileTypes: ['text/plain'],
        lineNumberFormatter: (line) => (line && lineNumberStart) ? dec2hex(line + hex2dec(lineNumberStart) - 1, 4).toString() : '➤',
        autoRefresh: true,
        // extraKeys: {
        //   "Ctrl-Space": "autocomplete"
        // },
      }}
      onChange={(editor) => onChange(editor.getValue())}
      height='100%'
    />
  </div>
)
);


