import instructions from '../assets/json/operations.json';
import { dec2hex, hex2dec } from '../utils/operations';

let setEtiquetas = [];
const parseInput = (text, initDir) => {
  setEtiquetas = [];
  let results = [];
  const lines = text.split('\n');

  pre(lines);

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    if (!line) continue;

    const result = executeRegex(line, initDir, results);
    if (!result) continue;
    results.push(result);
  }

  results = post(results, initDir);

  return {
    errors: getErrors(results, lines),
    results: results.map((s) => s.result),
  };
};

const getErrors = (results, lines) => {
  const errors = [];
  const calculatedLength = lines.filter((l) => l.length > 0).length;

  if (results.length !== calculatedLength) {
    return [
      {
        lineFrom: 0,
        lineTo: lines.length,
      },
    ];
  }
  for (let i = 0; i < results.length; i++) {
    const result = results[i];

    if (!result.result || result.instruction.ALLOC !== result.result.length) {
      errors.push({ lineFrom: i, lineTo: i });
    }
  }
  return errors;
};

const pre = (lines) => {
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    for (let j = 0; j < instructions.length; j++) {
      const instruction = instructions[j];

      const words = line.split(' ');
      const posibleSetEti = words[0];
      const line2 = words.slice(1, words.length);
      const result = new RegExp(instruction.REGEX, 'gim').exec(line2.join(' '));

      if (result && result.length > 1) {
        // eti = posibleSetEti;
        // const pos = dec2hex(hex2dec(initDir) + results.length, 4);
        setEtiquetas.push({ eti: posibleSetEti });
      }
    }
  }
};
const post = (results, initDir) => {
  const etiPositions = calculateEtiquetasPos(results, initDir);

  for (let i = 0; i < results.length; i++) {
    const result = results[i];
    if (!result.result) continue;

    for (let j = 1; j < result.result.length; j++) {
      const r = result.result[j];
      const eti = etiPositions.find((s) => s.eti.eti === r);
      if (eti) {
        // console.info("Found eti at", result.result);
        result.result.splice(j, 1);
        result.result.push(eti.dir.slice(0, 2), eti.dir.slice(2));
      } else if (!/[0-9A-Fa-f]{2}/g.test(r)) {
        // is not !hex && !eti => error
        results.splice(i, 1);
      }
    }
  }
  return results;
};
const executeRegex = (line, initDir, results) => {
  for (let i = 0; i < instructions.length; i++) {
    const instruction = instructions[i];

    let eti;
    let result = new RegExp(instruction.REGEX, 'gim').exec(line);
    if (!result) {
      const words = line.split(' ');
      const posibleSetEti = words[0];
      const line2 = words.slice(1, words.length);
      result = new RegExp(instruction.REGEX, 'gim').exec(line2.join(' '));

      if (result && result.length > 1) {
        eti = posibleSetEti;
      }
    }

    if (result && result.length > 1) {
      const ss = translate(instruction, result.slice(1, result.length));
      return {
        line, result: ss, eti, instruction,
      };
    }
  }
};

const translate = (instruction, ops) => {
  const { length } = ops;
  const OPHexCode = instruction.HEX;

  if (length === 1) {
    return [OPHexCode];
  } if (length === 2) {
    if (instruction.GRUPO < 4) {
      return [OPHexCode];
    }
    const inmORDir = ops[1];
    const isDir = instruction.NEMO.includes('dir');
    const isInm = instruction.NEMO.includes('inm');
    if (!inmORDir) return;

    if (inmORDir.toUpperCase().startsWith('ET')) {
      return [OPHexCode, inmORDir];
    }

    if (isDir && inmORDir.length !== 4) {
      return;
    }

    if (isInm && inmORDir.length !== 2) {
      return;
    }

    if (isDir) {
      return [OPHexCode, inmORDir.slice(0, 2), inmORDir.slice(2)];
    }
    return [OPHexCode, inmORDir.slice(0, 2)];
  } if (length === 3) {
    // only one code
    if (instruction.GRUPO < 4) {
      return [OPHexCode];
    }
    const inmORDir = ops[1];
    const isDir = instruction.NEMO.includes('dir');
    const isInm = instruction.NEMO.includes('inm');
    if (!inmORDir) return;

    if (inmORDir.toUpperCase().startsWith('ET')) {
      return [OPHexCode, inmORDir];
    }

    if (isDir && inmORDir.length !== 4) {
      return;
    }

    if (isInm && inmORDir.length !== 2) {
      return;
    }

    if (isDir) {
      return [OPHexCode, inmORDir.slice(0, 2), inmORDir.slice(2)];
    }
    return [OPHexCode, inmORDir.slice(0, 2)];
  }
};

const calculateEtiquetasPos = (results, initDir) => {
  // console.info("--------------");
  const toReturn = [];
  const defEtiquetas = results.filter((r) => r.eti);
  for (let i = 0; i < defEtiquetas.length; i++) {
    const defEtiqueta = defEtiquetas[i];
    let sum = 0;
    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      if (!result.result) continue;
      if (result === defEtiqueta) {
        break;
      } else {
        console.info(
          result.line,
          result.result,
          `+${result.instruction.ALLOC}`,
          '=>',
          sum,
        );
        sum += result.instruction.ALLOC;
      }
    }
    toReturn.push({
      eti: defEtiqueta,
      dir: dec2hex(hex2dec(initDir) + sum, 4),
    });
    console.info(
      `Index hasta declaracion de et: ${defEtiqueta.eti} => ${sum}`,
    );
  }
  return toReturn;
};

export { parseInput };
